var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Invoice',{ref:"InvoiceRef",on:{"invoice-created":_vm.handleInvoiceCreated,"invoice-updated":_vm.handleInvoiceUpdated,"invoice-deleted":_vm.handleInvoiceDeleted}}),_c('p',{staticClass:"caption pasive--text text-right"},[_vm._v(_vm._s(_vm.totalitems)+" resultados")]),_c('v-data-table',{class:_vm.clickable ? 'row-pointer' : '',attrs:{"headers":_vm.headers ? _vm.headers : _vm.defaultHeaders,"items":_vm.items,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Cargando...","no-data-text":'Sin registros',"item-key":"id","item-class":"text-center","disable-sort":"","footer-props":{
      itemsPerPageText: 'Resultados por pág.',
      itemsPerPageAllText: 'Todos',
      pageText: '',
      showCurrentPage: true,
      itemsPerPageOptions: [15, 30, 60],
    },"options":_vm.options,"server-items-length":_vm.totalitems},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.date",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.date))+" ")]}},{key:"item.id",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatInvoiceId(props.item))+" ")]}},{key:"item.patient",fn:function(props){return [(props.item.patient)?_c('span',[_vm._v(_vm._s(props.item.patient.firstName)+" "+_vm._s(props.item.patient.lastName))]):_vm._e()]}},{key:"item.location",fn:function(props){return [_vm._v(" "+_vm._s(props.item.location ? props.item.location.name : "-")+" ")]}},{key:"item.appointment",fn:function(props){return [_vm._v(" "+_vm._s(props.item.appointment ? props.item.appointment.reason : "-")+" ")]}},{key:"item.totalDiscount",fn:function(props){return [_vm._v(" "+_vm._s(props.item.totalDiscount)+" % ")]}},{key:"item.total",fn:function(props){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatMoney(props.item.total)))])]}},{key:"item.updatedAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(props.item.updatedAt))+" ")]}},{key:"item.payment",fn:function(props){return [_c('InvoiceBanner',{attrs:{"invoice":props.item,"clickable":false}})]}},{key:"item.createdBy",fn:function(props){return [(props.item.createdBy)?_c('span',[_vm._v(_vm._s(props.item.createdBy.firstName)+" "+_vm._s(props.item.createdBy.lastName)+" ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }